import {arrayMoveMutable} from 'array-move';

export default class ArrUtils {

    static moveInDir<T>(dir: number, items: T[], itemIndex: number) {
        // handle edges
        let newIndex: number;
        if (dir === -1 && itemIndex === 0) {
            newIndex = items.length - 1;
        } else if (dir === 1 && itemIndex === items.length - 1) {
            newIndex = 0;
        } else {
            newIndex = itemIndex + dir;
        }
         arrayMoveMutable(items, itemIndex, newIndex);
    }

    static safeMove<T extends { order?: number }>(items: T[], itemIndex: number, newIndex: number) {
        arrayMoveMutable(items, itemIndex, newIndex);


        for (let i = 0; i < items.length; i++) {
            items[i].order = i;
        }
    }

    static safeMoveInDir<T extends { order?: number }>(dir: number, items: T[], itemIndex: number) {
        this.moveInDir(dir, items, itemIndex);


        for (let i = 0; i < items.length; i++) {
            items[i].order = i;
        }
    }


}