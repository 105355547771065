


















































import Component from "vue-class-component";
import Vue from "vue";
import {Model, Prop} from "vue-property-decorator";
import WallboardModule from "@/common/module/WallboardModule";
import {getModule} from "vuex-module-decorators";
import FilterMember from "@/pb/data/FilterMember";
import FilterMemberAndExts from "@/pb/data/FilterMemberAndExts";
import MemberType from "@/pb/data/MemberType";

interface ProcFilterOpt {
    label: string;
    value: string;
    no?: string;
    group?: string;
    profileImage?: string;
}

@Component
export default class FilterPicker extends Vue {
    @Prop({type: String})
    label!: string

    @Model('update:modelValue', {
        type: String
    })
    modelValue!: string;

    get myFilters(): string[] {
        const filters = this.myFilter === "" ? [""] : this.myFilter.split(';');
        console.log('get myFilters', filters);
        return filters;
    }

    get myFilter(): string {
        return this.modelValue;
    }

    set myFilter(f: string) {
        this.$emit('update:modelValue', f);
    }

    set myFilters(f: string[]) {
        console.log('set myFilters', f);
        this.myFilter = f.filter(f => f.length > 0).join(';');
    }

    mounted() {
        if (this.modelValue === "")
            return;

        if (!this.filterOptions.map(opt => opt.value).includes(this.modelValue)) {
            this.modelValue = '';
        }
    }

    get filterOptions(): ProcFilterOpt[] {
        const rawOpts = this.wallboardMod.filterOptions;
        if (rawOpts === null)
            return [];


        const opts: ProcFilterOpt[] = [
            {
                label: "All",
                value: ""
            }
        ];

        const mapExts = FilterPicker.mapMems.bind(null, opts);
        const mapGroups = FilterPicker.mapGroups.bind(null, opts);

        mapGroups('Queues', rawOpts.queues, 'Q');
        mapGroups('Ring Groups', rawOpts.ringGroups, 'G');
        mapGroups('Groups', rawOpts.groups, 'E');
        mapExts('Extensions', rawOpts.extensions, 'X');

        return opts;
    }


    get wallboardMod(): WallboardModule {
        return getModule(WallboardModule, this.$store);
    }

    get tcxUrl(): string {
        return `https://${this.wallboardMod.wb.backend.fqdn}`;
    }

    static mapMem(group: string, ext: FilterMember, valuePrefix: string): ProcFilterOpt {
        let name = ext.name;
        const value = ext.type === MemberType.Extension ? ext.no : ext.name;
        if (ext.type !== MemberType.ExtensionGroup) {
            name += ` (${ext.no})`;
        }
        return {
            label: name,
            value: `${valuePrefix}:${value}`,
            profileImage: ext.profileImage,
            group: group,
            no: ext.type === MemberType.ExtensionGroup ? undefined : ext.no
        };
    }

    static mapMems(opts: ProcFilterOpt[], group: string, exts: FilterMember[], prefix: string) {

        for (const ext of exts) {
            opts.push(FilterPicker.mapMem(group, ext, prefix))
        }
    }

    remove(mem: ProcFilterOpt): void {
        console.log('remove myFilter: ', mem);
        this.myFilters = this.myFilters.filter(f => f != mem.value);
        console.log(this.myFilters);
    }

    static mapGroups(opts: ProcFilterOpt[], group: string, groups: FilterMemberAndExts[], valuePrefix: string) {
        for (const groupData of groups) {
            opts.push(FilterPicker.mapMem(group, groupData.group, valuePrefix));
        }
    }

    searchFilter(item: ProcFilterOpt, queryText: string) {
        if (queryText.length === 0) {
            return true;
        }
        const itemLbl = item.label.toLowerCase();
        const search = queryText.toLowerCase();

        if (itemLbl.includes(search)) {
            return true;
        }

        if (item.group !== undefined) {
            const groupLbl = item.group.toLowerCase();
            if (groupLbl.includes(search)) {
                return true;
            }
        }
        const val = item.value.toLowerCase();
        return val.includes(search);
    }

    hasProfileImg(item: FilterMember): boolean {
        return (item?.profileImage?.length ?? 0) > 0;
    }

}
