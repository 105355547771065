




























































import Vue from "vue";
import {ModelSync} from "vue-property-decorator";
import Component from "vue-class-component";
import ColorPalettePicker from "@/components/ColorPalettePicker.vue";
import ColorDisplay from "@/components/ColorDisplay.vue";
import ColumnManager from "@/components/pb/ColumnManager.vue";
import TileType from "@/pb/data/TileType";
import TileTypeUtils from "@/pb/utils/TileTypeUtils";
import StatPicker from "@/components/pb/StatPicker.vue";
import StatPickerOption from "@/pb/StatPickerOption";
import FilterPicker from "@/components/pb/FilterPicker.vue";
import StatHelpers from "@/pb/StatHelpers";
import BoardTile from "@/pb/data/BoardTile";
import StringUtils from "@/common/utils/StringUtils";
import BestExtDisplayMode from "@/pb/data/BestExtDisplayMode";

@Component({
    components: {FilterPicker, StatPicker, ColumnManager, ColorDisplay, ColorPalettePicker}
})
export default class TileForm extends Vue {
    scale: any = 1;
    @ModelSync('modelValue', 'update:modelValue', {type: Object})
    tile!: BoardTile;
    settingsOpen: number[] = [1, 2];

    values: { value: TileType, label: string }[] = [];
    bestItems: { value: BestExtDisplayMode, label: string }[] = [];

    mounted() {
        for (const tileType of Object.values(TileType)) {
            this.values.push({
                label: TileTypeUtils.getName(tileType),
                value: tileType
            })
        }
        for (const bestType of Object.values(BestExtDisplayMode)) {
            this.bestItems.push({
                label: StringUtils.humanize(bestType),
                value: bestType
            });
        }
    }

    hasStatArg(tile: BoardTile): boolean {
        console.log('hasStatArg', tile);
        return TileTypeUtils.hasStatArg(tile.type);
    }

    isTable(): boolean {
        return TileTypeUtils.isTable(this.tile.type);
    }

    get columnItems(): StatPickerOption[] {

        return this.tile.tableColumns.map(c => {
            const ty = c.columnType;
            return {
                value: ty,
                fullLabel: StatHelpers.getStatFullNameByValue(ty) ?? "",
                pickerLabel: StatHelpers.getStatPickerNameByValue(ty) ?? ""
            }
        });
    }
}
