

















import Component from "vue-class-component";
import Vue from "vue";
import Misc from "@/common/Misc";
import {ModelSync} from "vue-property-decorator";

interface VuetifyColors {

    alpha?: number,
    hex: string,
    hexa?: string,
    hsla?: {
        h: number,
        s: number,
        l: number,
        a: number
    },
    hsva?: {
        h: number,
        s: number,
        v: number,
        a: number
    },
    hue?: number,
    rgba?: {
        r: number,
        g: number,
        b: number,
        a: number
    }
}

@Component
export default class ColorPalettePicker extends Vue {
    static readonly lowContrast: string[] = [
        '#FF8686',
        '#F8C359',
        '#7BB6FF',
        '#FFFFFF',
        '#BADEA7',
        '#242424',
        '#F8FF9C',
        '#FFBAFD',
        '#9DF5FF',
        '#FF81BC',
        '#D78BFD',
        '#9766E1',
        '#5DAED1',
        '#64C3B0',
        '#E08D4D',
        '#B6DC75',
    ];
    static readonly highContrast: string[] = [
        '#E70000',
        '#FFAA00',
        '#0072FF',
        '#FFFFFF',
        '#47D800',
        '#242424',
        '#EEFF00',
        '#FF00F8',
        '#00CDE4',
        '#FF1080',
        '#A800FB',
        '#6500FB',
        '#00B0FB',
        '#00FBC9',
        '#FB6D00',
        '#A0FB00',
    ];


    @ModelSync('modelValue', 'update:modelValue', {type: String})
    color!: string;
    palette: string[] = ColorPalettePicker.highContrast;
    lastRawColor!: VuetifyColors | null;
    paletteOptions = [
        {"name": "High Contrast", value: ColorPalettePicker.highContrast},
        {"name": "Low Contrast", value: ColorPalettePicker.lowContrast},
    ];

    get swatches() {
        return Misc.listToMatrix(this.palette, 4);
    }

    onColorUpdate(color: VuetifyColors) {
        this.lastRawColor = color;
        this.color = color.hex;
        console.log('color update', color?.hex + " => " + this.color);
    }

    mounted() {
        const hex = this.color;
        for (const paletteOpt of this.paletteOptions) {
            if (paletteOpt.value.includes(hex)) {
                this.palette = paletteOpt.value;
                console.log(`found palette for: ${hex} => ${paletteOpt.name}`)
                break;
            }
        }
        this.lastRawColor = {hex: hex};
        this.color = hex;
    }
}
