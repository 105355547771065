








































import Vue from "vue";
import Component from "vue-class-component";
import {ModelSync, Watch} from "vue-property-decorator";
import TilesGrid from "@/pb/TilesGrid";
import WallboardModule from "@/common/module/WallboardModule";
import {getModule} from "vuex-module-decorators";
import BoardTileUtils from "@/pb/utils/BoardTileUtils";
import TileForm from "@/components/pb/TileForm.vue";
import BoardTile from "@/pb/data/BoardTile";
import EditDialog from "@/components/EditDialog.vue";


@Component({
    components: {EditDialog, TileForm}
})
export default class TileDisplay extends Vue {
    showSettings: boolean = false;


    get grid(): TilesGrid {
        return this.wbMod.grid;
    }

    get wbMod(): WallboardModule {
        return getModule(WallboardModule, this.$store);
    }

    get title(): string {
        return this.tile ? BoardTileUtils.tileName(this.tile) : '';
    }

    @ModelSync('modelValue', 'update:modelValue', {type: Object})
    tile!: BoardTile;

    get canDuplicate(): boolean {
        return this.grid.canCreateOrDuplicate;
    }

    get tileStyle(): any {
        return {
            height: '100%',
            gridArea: `${this.tile.row} / ${this.tile.column} / span ${this.tile.rows} / span ${this.tile.columns}`
        };
    }

    async duplicateAsync() {
        await this.wbMod.duplicateTile(this.tile);
    }


    async deleteAsync() {

        await this.wbMod.deleteTile(this.tile);
    }

    duplicate() {
        this.duplicateAsync().catch(err => console.error(err));
    }


    deleteItem() {
        this.deleteAsync().catch(console.error);
    }


    @Watch('tile', {deep: true})
    updateTileWatch() {
        const tile = this.tile;
        this.wbMod.updateTile({
            id: tile.id,
            columns: tile.columns,
            rows: tile.rows,
            column: tile.column,
            row: tile.row,
            bgColor: tile.bgColor,
            fgColor: tile.fgColor,
            scale: tile.scale,
            args: tile.args,
            filter: tile.filter,
            customTitle: tile.customTitle ?? "",
            type: tile.type
        });

        if (this.wbMod.token?.length && this.wbMod.backendRest !== null)
            this.wbMod.backendRest.forceConfigReloadAsync(this.wbMod.token).catch(console.error);
    }
}
