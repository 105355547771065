




















































import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import TableColumn from "@/pb/data/TableColumn";
import EditDialog from "@/components/EditDialog.vue";
import WallboardModule from "@/common/module/WallboardModule";
import {getModule} from "vuex-module-decorators";
import MyPbxToolsApi from "@/common/MyPbxToolsApi";
import StatPicker from "@/components/pb/StatPicker.vue";

import Draggable from 'vuedraggable';
import {SortableEvent} from "sortablejs";
import StatHelpers from "@/pb/StatHelpers";
import {DataOptions} from "vuetify";
import BoardTile from "@/pb/data/BoardTile";
import MyColumn from "@/pb/data/MyColumn";
import ArrUtils from "@/common/utils/ArrUtils";

@Component({
    components: {StatPicker, EditDialog, Draggable}
})
export default class ColumnManager extends Vue {
    @Prop({type: Object})
    tile!: BoardTile;

    options: DataOptions = {
        itemsPerPage: Number.POSITIVE_INFINITY,
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        page: 0,
        mustSort: false,
        sortBy: [],
        sortDesc: []
    };
    headers: any[] = [
        {text: 'Type', value: 'columnType'},
        {text: 'Title', value: 'customTitle'},
        {text: 'Act', value: 'actions', align: 'right'}
    ]
    prototype: string = "totalCalls";

    get columns(): MyColumn[] {
        return this.tile.tableColumns.map(col => {
            const title = this.autoItemName(col);
            return {
                defaultTitle: title,
                ...col,
                customTitle: title,
            };
        });
    }

    get wallboardMod(): WallboardModule {
        return getModule(WallboardModule, this.$store);
    }

    get api(): MyPbxToolsApi {
        return MyPbxToolsApi.instance;
    }

    get items(): TableColumn[] {
        return this.tile.tableColumns;
    }

    set items(cols: TableColumn[]) {
        this.tile.tableColumns = cols;
    }

    itemName(value: string) {
        return StatHelpers.getStatFullNameByValue(value) ?? `Unknown column: ${value}`;
    }

    autoItemName(col: TableColumn) {
        return col.customTitle?.length ? col.customTitle : this.itemName(col.columnType);
    }

    async deleteItemAsync(col: TableColumn) {
        await this.doActionAsync('delete', {columnId: col.columnId});

        this.items = this.items.filter(c => c.columnId !== col.columnId);
    }

    async createAsync(statType: string) {
        const item = await this.doActionAsync<TableColumn>('create', {columnType: statType});
        this.items.push(item);
    }

    createItem(statType: string) {
        this.createAsync(statType).catch(console.error);
    }

    deleteItem(col: TableColumn) {

        this.deleteItemAsync(col).catch(console.error);
    }

    applyDragSort(ev: SortableEvent) {
        this.moveItemRaw(ev.oldIndex!, ev.newIndex!);
    }

    applySort() {
        this.applySortAsync().catch(console.error);
    }

    moveItemRaw(fromIndex: number, toIndex: number) {
        ArrUtils.safeMove(this.items, fromIndex, toIndex);
        this.applySort();
    }

    async applySortAsync() {
        await this.doActionAsync('apply_sort', {
            colIds: this.items.map((t: TableColumn) => t.columnId).join(",")
        });
    }

    async doActionAsync<TRes>(action: string, data: any = {}): Promise<TRes> {
        data.wallboardId = this.wallboardMod.wb.id;
        data.tileId = this.tile.id;
        return MyPbxToolsApi.instance.doWbActionGeneric('tables_cols', action, data);
    }

    updateColumn(col: MyColumn) {
        this.updateColumnAsync(col).catch(console.error);
    }

    async updateColumnAsync(col: MyColumn) {

        await this.doActionAsync<Partial<TableColumn>>('update', {
            columnId: col.columnId,
            columnType: col.columnType,
            customTitle: col.customTitle == col.defaultTitle ? null : col.customTitle
        });
        await this.wallboardMod.backendRest!.forceConfigReloadAsync(this.wallboardMod.wb.token);
    }
}
