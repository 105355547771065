

























import Vue from "vue";
import Component from "vue-class-component";
import _startCase from "lodash.startcase";
import {Model, Prop} from "vue-property-decorator";

interface IntervalOption {
    value: string;
    label: string;
    default?: string;
}

@Component
export default class IntervalPicker extends Vue {
    @Prop({type: String})
    hint!: string;
    options: IntervalOption[] = [
        {value: "never", label: "Never"},
        {value: "daily", label: "Daily at ", default: '00:00'},
        {value: "weekly", label: "Weekly on ", default: 'monday'},
        {value: "monthly", label: "Monthly on ", default: '00-00:00'},
        {value: "hourly", label: "Hourly starting at", default: '0'},
    ];
    days = [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday"
    ].map(v => ({label: _startCase(v), value: v}));

    @Model('update:modelValue', {type: String})
    modelValue!: string;

    get intervalParts(): string[] {
        return this.modelValue?.split(";") ?? [];
    }

    set intervalParts(value: string[]) {
        this.$emit('update:modelValue', value.join(';'));
    }

    get intervalType(): string {
        return this.modelValue === "" ? "never" : this.intervalParts[0];
    }

    set intervalType(v: string) {
        if (v === this.modelValue)
            return;

        this.intervalParts = [v, this.getDefault(v)];
    }

    get intervalValue(): string {
        return this.intervalParts[1] ?? "";
    }

    set intervalValue(v: string) {
        this.intervalParts = [this.intervalParts[0], v];
    }

    get intervalValueInt(): number {
        return parseInt(this.intervalValue)
    }

    set intervalValueInt(v: number) {
        this.intervalValue = v.toString();
    }

    getDefault(ty: string): string {
        return this.options.find(opt => opt.value === ty)?.default ?? "";
    }

}
