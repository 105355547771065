export default class Misc {
    static listToMatrix<TEl>(list: TEl[], elsPerSub: number): TEl[][] {
        const matrix: TEl[][] = [];

        for (let i = 0, k = -1; i < list.length; i++) {
            if (i % elsPerSub === 0) {
                k++;
                matrix[k] = [];
            }
            matrix[k].push(list[i]);
        }
        return matrix;
    }
}