

















































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import NavDrawer from "@/components/NavDrawer.vue";
import App from "@/App.vue";
import UserData from "@/common/data/UserData";
import CommonModule from "@/common/module/CommonModule";
import {getModule} from "vuex-module-decorators";
import WallboardModule from "@/common/module/WallboardModule";
import Board from "@/pages/powerboards/Board.vue";
import BoardSettings from "@/components/pb/BoardSettings.vue";

@Component({
    components: {BoardSettings, NavDrawer, Board}
})
export default class PowerboardLayout extends Vue {
    showDrawer: boolean = false;
    settingsOpen: string[] = [];
    showSettings: boolean = false;
    @Prop({type: Boolean})
    isTemplate!: boolean;
    showPreview: boolean = true;

    mounted() {
        if (localStorage.previewDisabled)
            this.showPreview = !localStorage.previewDisabled;

    }

    @Watch('showPreview') showPreviewChanged(newShow: boolean) {
        localStorage.previewDisabled = !newShow;


    }

    get previewHint(): string {
        return this.showPreview ? "Hide Preview" : "Show Preview";
    }

    get previewIcon(): string {
        const prefix = 'mdi-table-eye'
        return !this.showPreview ? prefix : `${prefix}-off`;
    }

    $refs!: {
        board: Board
    }

    get app(): App {
        return this.$parent as App;
    }

    get title(): string {
        return this.app.pageTitle + ": " + this.wallboardData.wb.title;
    }

    get commonData(): CommonModule {
        return getModule(CommonModule, this.$store);
    }

    get wallboardData(): WallboardModule {
        return getModule(WallboardModule, this.$store);
    }

    get userData(): UserData | null {
        return this.commonData.userData;
    }

    get isLoggedIn(): boolean {
        return !this.commonData.needsLogin;
    }

    get siteName(): string {

        const data: CommonModule = this.commonData;
        return data.needsLogin || data.userData === null ? "MyPBXTools" : data.userData.company;
    }

    refresh() {
        this.$refs.board.reload();
    }
    forceRefreshBoard() {
        getModule(WallboardModule, this.$store).forceConfigReload();
    }

}
