import TileType from "@/pb/data/TileType";

export default class TileTypeUtils {
    static readonly statArgs: Set<TileType> = new Set<TileType>();

    static hasStatArg(type: TileType): boolean {
        console.log('type hasstat', type);
        return this.statArgs.has(type);
    }

    static getName(type: TileType): string {
        switch (type) {
            case TileType.BestExtension:
                return "Best Extension";
            case TileType.ExtensionsTable:
                return "Extensions Table";
            case TileType.ExtensionChart:
                return "Extension Chart";
            case TileType.Heading:
                return "Heading";
            case TileType.ExportAbandonedForm:
                return "Export Abandoned Form";
            case TileType.PercentStatSummary:
                return "Percent Stat Summary (Numerator / Numerator+Denominator)";
            case TileType.Stat:
                return "Stat";
            default:
                return "???";
        }
    }

    static isTable(type: TileType): boolean {
        return type === TileType.ExtensionsTable;
    }
}

TileTypeUtils.statArgs.add(TileType.PercentStatSummary);
TileTypeUtils.statArgs.add(TileType.Stat);

TileTypeUtils.statArgs.add(TileType.BestExtension);
