



























import Component from "vue-class-component";
import WallboardModule from "@/common/module/WallboardModule";
import {getModule} from "vuex-module-decorators";
import StatPicker from "@/components/pb/StatPicker.vue";
import Vue from "vue";
import FilterPicker from "@/components/pb/FilterPicker.vue";
import ExtBoardData from "@/pb/data/ExtBoardData";
import {Watch} from "vue-property-decorator";
import CommonModule from "@/common/module/CommonModule";
import IntervalPicker from "@/components/pb/IntervalPicker.vue";

@Component({
    components: {IntervalPicker, FilterPicker, StatPicker}
})
export default class BoardSettings extends Vue {
    settingsOpen: string[] = ["general"];


    get common(): CommonModule {
        return getModule(CommonModule, this.$store);
    }

    get wallboardData(): WallboardModule {
        return getModule(WallboardModule, this.$store);
    }

    get wb(): ExtBoardData {
        return this.wallboardData.wb;
    }

    @Watch('wb', {deep: true}) boardChanged(to: ExtBoardData) {
        this.updateBoardAsync(to).catch(console.error);
    }

    async updateBoardAsync(to: ExtBoardData) {
        await this.common.api.doWbAction(to.id, 'update', {
            globalFilter: to.globalFilter,
            title: to.title,
            bestCriteria: to.bestCriteria,
            statsResetInterval: to.statsResetInterval,
        });
        await this.wallboardData.backendRest?.forceConfigReloadAsync(to.token);
    }

}
