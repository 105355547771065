



































import Component from "vue-class-component";
import Vue from "vue";
import ColorPalettePicker from "@/components/ColorPalettePicker.vue";
import TileDisplay from "@/components/pb/TileDisplay.vue";
import BackendRest from "@/pb/BackendRest";
import {getModule} from 'vuex-module-decorators';
import WallboardModule from "@/common/module/WallboardModule";
import CommonModule from "@/common/module/CommonModule";
import ExtBoardData from "@/pb/data/ExtBoardData";
import Instance from "@/pb/data/BaseInstance";
import FilterData from "@/pb/data/FilterData";
import {Prop} from "vue-property-decorator";
// @ts-ignore
import {GridItem, GridLayout} from "vue-grid-layout-ex";
import BoardTile from "@/pb/data/BoardTile";


interface TileLayoutData {
    x: number;
    y: number;
    w: number;
    h: number;
    i: number;
}

@Component({
    components: {TileDisplay, ColorPalettePicker, GridLayout, GridItem}
})

export default class Board extends Vue {
    loading: boolean = false;
    get instanceRest(): BackendRest | null {
        return this.wbModule.backendRest;
    }

    get token(): string {
        return this.$route.params.token;
    }

    move(index: number, x: number, y: number) {
        // [index, x, y]
        const tile = this.wb.tiles[index];
        Object.assign(tile, {row: y, column: x});

        this.wbModule.updateTile({
            id: tile.id,
            column: tile.column,
            row: tile.row
        });
    }

    resize(index: number, h: number, w: number) {
        // [index, x, y]
        const tile = this.wb.tiles[index];
        Object.assign(tile, {rows: h, columns: w});

        this.wbModule.updateTile({
            id: tile.id,
            columns: tile.columns,
            rows: tile.rows
        });
    }
    showSettings: boolean = false;
    filterGroups: FilterData | null = null;

    @Prop({type: Boolean})
    showPreview: boolean = true;


    get wb(): ExtBoardData {
        return this.wbModule.wb;
    }

    get wbModule(): WallboardModule {
        return getModule(WallboardModule, this.$store);
    }

    get commonModule(): CommonModule {
        return getModule(CommonModule, this.$store);
    }

    get layout(): TileLayoutData[] {
        return this.wb.tiles.map((el, i) => ({
            x: el.column,
            y: el.row,
            w: el.columns,
            h: el.rows,
            i: i,
        }));
    }
    

    get isTemplate(): boolean {
        return this.wb.template;
    }

    get instance(): Instance {
        return this.wb.backend;
    }

    mounted() {
        this.reload();
    }

    reload() {

        this.reloadAsync().catch(err => console.error(err));
    }

    async reloadAsync() {
        this.loading = true;
        await this.wbModule.trueInit(this.token);

        if (!this.isTemplate && this.instanceRest !== null && this.instance.fqdn !== "N/A") {
            this.filterGroups = await this.instanceRest.getFilterGroupsAsync();
        }
        this.loading = false;
    }

    getTileStyle(tile: BoardTile): any {
        return {
            height: '100%',
            gridRow: `${tile.row + 1} / span ${tile.rows}`,
            gridColumn: `${tile.column + 1} / span ${tile.columns}`
        };

    }

    get previewUrl(): string {
        return `https://powerboard.mypbxtools.com/${this.wb.token}/#preview`;
    }
}
