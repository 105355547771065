

















import Vue from "vue";
import Component from "vue-class-component";
import {ModelSync} from "vue-property-decorator";
import ColorPalettePicker from "@/components/ColorPalettePicker.vue";

@Component({
    components: {ColorPalettePicker}
})
export default class ColorDisplay extends Vue {
    @ModelSync('modelValue', 'update:modelValue', {type: String})
    color!: string
}
